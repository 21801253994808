<template>
  <div>
    <v-row>
      <v-col cols="5">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined

          @keyup="searching"
          hint="Type search criteria and press Enter"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <file-upload-button class="ml-10" @change="newFiles" @newText="newText" button-text="Add Document" @opened="fileDialogOpened" :files="newAttachments"></file-upload-button>
      </v-col>
    </v-row>
    <div v-if="documents.length === 0">
      No documents found
    </div>

      <v-row>
        <v-col cols="12">
        <documents :hide-folder-list="hideFolders" :files="documents" @download="download" @delete="remove" @change="change"></documents>
        </v-col>
      </v-row>


    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="add"
            >
              Save New Documents
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

      </v-card>
    </v-dialog>

    <text-document :document="newTextDoc" :show="docDialog" @save="saveTextDoc" @cancel="docDialog = false"></text-document>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import FileUpload from "@/components/file/FileUpload";
import Documents from "@/components/file/DocumentList";
import FileUploadButton from "@/components/file/FileUploadButton";
import TextDocument from "@/components/ingram/documents/TextDocument";

export default {
  name: "DocumentList",
  props:{
    documents: Array,
    canAdd: Boolean,
    canDelete: Boolean
  },
  components:{FileUploadButton, Documents, TextDocument},
  computed: {
    ...mapGetters({
      user: 'app/getUser'
    }),
    ...mapState('app', ["organizations", "categories", "studentToken"])
  },
  data(){
    return {
      hideFolders: false,
      search:'',
      dialog:false,
      files:[],
      newAttachments: [],
      newTextDoc:{name: '', content:''},
      docDialog: false,
      docText: '',
    }
  },
  methods:{
    fileDialogOpened(){
      this.newAttachments = [];
    },
    newFiles(){
      this.$emit('add', this.newAttachments);
    },
    newText(){
      this.docText = '';
      this.docDialog = true;
    },
    undo(){
      this.$emit('undo');
    },
    remove(file){
      this.$emit('remove', file);
    },
    add(){
      this.dialog = false;
      this.$emit('add', this.files);
    },
    download(file){
      console.log(file);
      const a = document.createElement("a");
      a.href = file.url;
      a.setAttribute("download", file.name);
      a.click();
    },
    searching(e){
      const data = {keyCode: e.keyCode, search: this.search};
      this.$emit('search', data);
    },
    change(file){
      this.$emit('change', file);
    },
    saveTextDoc(){
      const vm = this;

      let doc = {
        name: vm.newTextDoc.name,
        description: vm.newTextDoc.content,
        thumbUrl: '/images/FileTypeTxt.png',
        image: null,
        edit: false,
        deleted: false,
        documentType: 2,
        studentToken: vm.user.identifier
      }

      this.$emit('add', doc);



      this.docDialog = false;

      //save
    },
  }
}
</script>

<style scoped>

</style>
