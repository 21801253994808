<template>
  <div>

  <div>
    <v-row>
      <v-col cols="12" md="3" v-for="(file, idx) in notDeletedFiles" :key="idx">
        <v-card>
          <v-card-title class="justify-end" style="padding-top:0;padding-bottom:0">
            <v-btn
              v-if="!file.edit"
              class="ma-2"
              tile

              color="red"
              icon
              @click="remove(file)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div style="height:350px; overflow: hidden;">
            <v-img :src="file.thumbUrl" max-height="350" contain="contain"></v-img>
            </div>
            <div>
              {{file.name}}
            </div>
            <div>
              <v-textarea v-model="file.description" label="Description" rows="2" :auto-grow="true"></v-textarea>
            </div>
          </v-card-text>
          <v-card-actions>


          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card>
          <div class="dropbox" @click="dropboxclick">
            <input ref="filebox" type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                   accept="image/*,application/*" class="input-file">
            <div style="text-align: center; ">
              <v-img :src="uploadImage" width="250" class="mx-auto"></v-img>
            </div>
            <p>
              Drag files here or click to select
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </div>
  </div>
</template>

<script>
import _ from "lodash";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
  name: "FileUpload.vue",
  props: ["files"],
  data(){
    return {
      contain:  true,
      mimeTypes: [
        'image/bmp',
        'image/gif',
        'image/apng',
        'image/jpeg',
        'image/svg+xml',
        'image/x-icon',
        'image/png',
        'image/pict',
      ],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      formData: null,
      uploadImage: require('@/assets/images/icon-upload.svg'),
      unknownFileImage: require('@/assets/images/FileTypeUnknown.png'),
      images:[],
    }
  },
  computed: {
    notDeletedFiles(){
      if(this.files !== undefined){
        return this.files.filter(function(item){return item.deleted === false});
      }
      else{
        return [];
      }
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods:{
    dropboxclick(){
      this.$refs.filebox.click();
    },
    edit(file){
      file.edit = true;
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.formData = new FormData();
    },
    filesChange(fieldName, fileList) {
      const vm = this;
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys())
        .map(x => {
          //vm.formData.append(fieldName, fileList[x], fileList[x].name);


          let url = vm.getImage(fileList[x]);
          vm.files.push({
            name: fileList[x].name,
            description: '',
            url: url,
            thumbUrl: url,
            image: fileList[x],
            edit: false,
            deleted: false
          });
        });

    },
    remove(file){
      if(file.url.indexOf('blob') === 0){
        let idx = this.files.indexOf(file);
        this.files.splice(idx, 1);
      }else{
        this.$emit('remove', file);
        //file.deleted = true;
      }

      //let idx = this.files.indexOf(file);
      //this.files.splice(idx, 1);
    },
    getImage(file){
      const urlCreator = window.URL || window.webkitURL;

      let imageUrl = '';
      if (this.mimeTypes.includes(file.type)) {
        imageUrl = urlCreator.createObjectURL(file)
      } else {
        imageUrl = this.unknownFileImage
      }

      return imageUrl;
    },
  },
  mounted(){
    this.reset();
  }
}
</script>

<style lang="scss" scoped>
.dropbox {
  /*outline: 2px dashed grey;*/ /* the dash box */
  outline-offset: -10px;
  /*background-color: #F2F5F8;*/
  color: dimgray;
  padding: 10px 10px;
  min-height: 150px; /* minimum height */
  position: relative;
  cursor: pointer;
  margin-bottom:20px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 150px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #05C075; /* when mouse over to the drop zone, change color */
  color:white;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
