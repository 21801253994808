<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Documents</h1>
    </div>
    <document-list :documents="documents" @remove="remove" :undo="undoLastDelete" @add="add" @search="searching" @change="change" :can-add=true :can-delete=true></document-list>


    <v-snackbar
      top
      :timeout="deletedAlert.timeout"
      v-model="deletedAlert.visible"
    >
      {{deletedAlert.message}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="undoLastDelete"
        >
          Undo
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import _ from 'lodash'
import {mapGetters, mapState} from "vuex";
import FileUpload from "@/components/file/FileUpload";
import DocumentList from "@/components/ingram/documents/DocumentList";
import Swal from "sweetalert2";


export default {
  name: "Documents",
  components: {DocumentList},
  data(){
    return {
      documents: [],
      deletedAlert: {
        visible: false,
        timeout: 5000,
        message: '',
        currentFolder: null,
      },
      lastDeleted:{}
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/getUser'
    }),
    ...mapState('app', ["organizations", "categories", "studentToken"])
  },
  methods:{
    selectFolder(folder){
      this.currentFolder = folder;
    },
    change(file){
      const vm = this;

      httpClient.post('/documents/update', file)
      .then(function(response){
        vm.$root.$emit('saveSuccess', {message: 'Changes Saved.'});
      });
    },
    undoLastDelete(){
      const vm = this;

      const id =  localStorage.getItem('lastDeletedDocument');

      httpClient.post('/documents/undo-remove', {studentToken: vm.studentToken, documentId: parseInt(id)})
        .then(function(response){
          vm.deletedAlert.visible = false;
          vm.load();
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to restore this file');
        });
    },
    remove(file){
      const vm = this;
      if(file.isService){
        Swal.fire({
          title: 'This document is part of a Service Log.  Delete it?',
          icon:'warning',
          showDenyButton: true,
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'Yes',
          denyButtonText: 'No'
        }).then((result) => {
          if(result.isConfirmed){
              vm.deleteDocument(file);
          }
        })
      }else{
        vm.deleteDocument(file);
      }
    },
    deleteDocument(file){
      const vm = this;

      localStorage.setItem('lastDeletedDocument', file.id);

      httpClient.post('/documents/remove', {studentToken: vm.studentToken, documentId: file.id})
        .then(function(response){
          vm.deletedAlert.message = `${file.name} deleted.`;
          vm.deletedAlert.visible = true;
          vm.load();

        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to delete this file');
        });
    },
    add(files){
      if(Array.isArray(files)){
        this.uploadDocuments(files)
      }else{
        this.addText(files);
      }
    },
    addText(file){
      const vm = this;

      httpClient.post('/documents/create-text', file)
        .then(function(response){
          vm.$root.$emit('saveSuccess', 'Changes saved');
          vm.load();
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to create document');
        });
    },
    uploadDocuments(files){
      const vm = this;
      vm.dialog = false;

      if(files.length === 0) return;

      let data = new FormData();

      let model = {};
      model.studentToken = vm.user.identifier;

      let metadata = [];

      files.forEach((x, idx) => {
        if(!x.deleted){
          data.append('photos', x.image, x.name);
          metadata.push({
            name: x.name,
            description: x.description,
            folder: vm.currentFolder
          });
        }
      });

      model.photoMetadata = metadata;
      data.append('model', JSON.stringify(model));

      httpClient.post('/documents/create', data)
        .then(function(response){
          if(response.data.success){
            vm.dialog = false;
            vm.files = [];
            vm.load();
          }else{
            console.log(response);
            vm.$root.$emit('saveFailed', {message: response.data.message});
            vm.saving = false;
          }

        })
        .catch(function(error){
          console.log(error);
          vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
          vm.saving = false;
        });


    },
    searching(e){
      const vm = this;
      if(e.keyCode === 13) {
        if(e.search === '' || e.search === undefined){
          this.load();
        }else{
          httpClient.get(`/documents/${vm.user.identifier}/search/${e.search}`)
            .then(function(response){
              vm.documents = response.data;
            });
        }
      }


    },
    load(){
      const vm = this;

      httpClient.get(`/documents/${vm.user.identifier}`)
        .then(function(response){
          vm.documents = response.data;
        });
    }
  },
  mounted(){
    const vm = this;
    this.load();
    vm.$root.$on('folderselection', vm.selectFolder);

  },
  beforeDestroy() {
    const vm = this;
    vm.$root.$off('folderselection', vm.selectFolder);
  },
}
</script>

<style scoped>

</style>
