<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Modify Service</h1>
    </div>

    <v-card>
      <v-card-text>
        <service-details ref="details" :service="service" :organizations="organizations" :categories="categories"></service-details>
        <v-row>
          <v-col cols="12">

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="pl-10 pr-10"  color="success" @click="save" :loading="saving">Done

          <template v-slot:loader>
            <span>Saving...</span>
          </template>
        </v-btn>
        <v-btn text @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-15">
      <v-card-title>
        Documents
        <file-upload-button class="ml-10" @change="newFiles" @newText="newText" button-text="Add Document" :files="newAttachments"></file-upload-button>
      </v-card-title>
      <v-card-text>
        <document-list :files="service.attachments" @delete="removeFile" @download="download" @change="hasChanges = true"></document-list>
      </v-card-text>
    </v-card>

    <text-document :document="newTextDoc" :show="docDialog" @save="saveTextDoc" @cancel="docDialog = false"></text-document>



  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import {mapGetters, mapState} from "vuex";
import ServiceDetails from "@/components/ingram/service/ServiceDetails";
import Swal from 'sweetalert2';
import DocumentList from "@/components/file/DocumentList";
import FileUploadButton from "@/components/file/FileUploadButton";
import TextDocument from "@/components/ingram/documents/TextDocument";

import navHelper from "@/mixins/NavMixin";

export default {
  name: "EditService",
  components:{ServiceDetails, DocumentList, FileUploadButton, TextDocument},
  mixins:[navHelper],
  data(){
    return {
      service:{serviceTime:'08:00', serviceEndTime:'09:00'},
      valid: true,
      hasChanges: false,
      saving:false,
      attachments:[],
      newAttachments:[],
      newTextDoc:{name: '', content:''},
      docDialog: false,
      docText: '',
      iconUrl: require('@/assets/images/FileTypeTxt.png'),
      currentFolder: null,
    }
  },
  computed:{
    ...mapState('app', ["organizations", "categories", "studentToken"]),
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
  },
  methods:{
    dirty(){
      this.hasChanges = true;
    },
    newText(){
      this.docDialog = true;
    },
    saveTextDoc(){
      const vm = this;

      let doc = {
        name: vm.newTextDoc.name,
        description: vm.newTextDoc.content,
        thumbUrl: '/images/FileTypeTxt.png',
        image: null,
        edit: false,
        deleted: false,
        documentType: 2,
        folder: vm.currentFolder
      }

      vm.service.attachments.push(doc);
      vm.hasChanges = true;
      this.docDialog = false;
    },
    newFiles(files){
     const vm = this;
     vm.hasChanges = true;
      vm.newAttachments.forEach(x => {
        x.folder = vm.currentFolder;
        vm.service.attachments.push(x);
      });

      vm.newAttachments = [];

    },
    download(file){
      const a = document.createElement("a");
      a.href = file.url;
      a.setAttribute("download", file.name);
      a.click();
    },
    removeFile(file){
      if(file.id === undefined){
        let idx = this.service.attachments.indexOf(file);
        this.service.attachments.splice(idx,1);
      }else{
        file.deleted = true;
      }

    },
    cancel(){
      this.goBack();
      //this.$router.go(-1);
      //this.$router.push('/');
    },
    save(){
      const vm = this;
      vm.saving = true;
      //build form data
      let data = new FormData();

      let model = vm.service;
      let metadata = [];

      vm.service.attachments.forEach((x, idx) => {
        if(!x.deleted && x.documentType === 1){
          if(x.url.indexOf('blob') <= 0) {
            data.append('photos', x.image, x.name);
            metadata.push({
              name: x.name,
              description: x.description,
              folder: x.folder
            });
          }

        }
      });
      const tokens = [vm.studentToken, vm.user.identifier];
      model.studentToken = tokens.find(x => {return x !== ''});
      model.photoMetadata = metadata;
      data.append('model', JSON.stringify(model));

      httpClient.post('/service/update', data)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Service Log Saved'});
            //vm.$router.push('/');
            //vm.$router.go(-1);
            vm.hasChanges = false;
            vm.goBack();
          }else{
            vm.$root.$emit('saveFailed', {message: response.data.message});
            vm.saving = false;
          }

        })
        .catch(function(error){
          console.log(error);
          vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
          vm.saving = false;
        });

    },
    validateDetails(){
      let isValid = this.$refs.details.validate();
      if(isValid) this.stepper = parseInt(this.stepper) + 1;
    },
    selectFolder(folder){
      console.log('page ' + folder);
      this.currentFolder = folder;
    }
  },
  mounted(){
    const vm = this;
    vm.id = this.$route.params.id;
    vm.$root.$on('folderselection', vm.selectFolder);

    httpClient.get(`/service/${vm.id}`)
    .then(function(response){
      vm.service = response.data;
    })
    .catch(function(error){
      console.log(error);
    });
  },
  beforeDestroy() {
    const vm = this;
    vm.$root.$off('folderselection', vm.selectFolder);
  },
  beforeRouteLeave(to, from, next){
    const vm = this;
    if(this.hasChanges){
      Swal.fire({
        title: 'You have unsaved changes, save them now?',
        icon:'warning',
        showDenyButton: true,
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No'
      }).then((result) => {
        if(result.isConfirmed){
          vm.save();
          next();
        }else{
          next();
        }
      })
        .catch(() => next(false));
    }else {
      next();
    }
  }
}
</script>

<style scoped>

</style>
